const projectsData = [
    {
      title: "whatsyourtune",
      shortDescription: "An innovative web application that combines state-of-the-art computer vision, machine learning, and generative music to explore the dynamic relationship between technology and human emotion.",
      fullDescription: "WhatsYourTune is a prime example of the powerful combination of artificial intelligence and artistic expression; it shows how technology can be leveraged to craft incredibly engaging and tailored user experiences. The technology provides a personalized piano melody that is based on the user's present feeling through the use of real-time emotion recognition. The application adjusts the visual atmosphere and music in response to changes in emotions. This project was marked with a grade of 1,3.",
      technologies: ["Python", "TensorFlow", "HTML/CSS/JS", "Google Magenta.js"],
      images: ["/images/happy_whatsyourtune.png", "/images/fear_whatsyourtune.png"],
      videoUrl: "https://www.youtube.com/embed/iltL_NA40os",
      liveUrl: "https://whatsyourtune.vercel.app/",
      githubUrl: "https://github.com/melihonat/whatsyourtune"
    },
    {
      title: "Soundmate",
      shortDescription: "A web application allowing users to log in with their Spotify account and check out their top artists and tracks, and generate custom playlists.",
      fullDescription: "The idea of Soundmate is that users log in with their Spotify account and check out their stats. They can also generate a custom playlist based on any song in their top tracks. The generated playlist immediately gets saved to their Spotify account. This project is work in progress, in the future I want this to be a social online forum where users can add friends and check out each other's stats, comment on and review new releases and much more. I currently need to add your Spotify account to the project's whitelist, so please contact me if you want to test it.",
      technologies: ["TypeScript", "React", "Node.js", "Tailwind CSS", "API Integration"],
      images: ["/images/topTracks.jpg", "/images/artistView.jpg"],
      liveUrl: "https://soundmate-eight.vercel.app/",
      githubUrl: "https://github.com/melihonat/soundmate"
    },
    {
      title: "NFL Fantasy Draft Assistant",
      shortDescription: "Web-based Fantasy Football Draft Assistant providing real-time rankings and personalized recommendations to help managers make informed draft decisions.",
      fullDescription: "A web application offering fantasy football managers real-time player rankings and personalized draft recommendations. Features include customizable league settings, an interactive draft board, and data-driven suggestions. Additionally, it helps users optimize their draft strategy with team need analysis.",
      technologies: ["React", "API Integration", "Tailwind CSS", "Postman & Swagger"],
      images: ["/images/LeagueSetup.jpg", "/images/DraftRoom.jpg"],
      liveUrl: "https://nfl-fantasy-draft-assistant-098e8a8dc5fc.herokuapp.com/",
      githubUrl: "https://github.com/melihonat/fantasy-draft-helper"
    },
];

export default projectsData;