import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Code, Palette, Database, Server, Cpu, Music } from 'lucide-react';

const ExperienceBox = ({ isOpen, years }) => {
  return (
    <div className="relative w-20 h-20">
      <motion.div
        className="absolute inset-0 bg-white dark:bg-gray-900 rounded-lg shadow-lg overflow-hidden"
        initial={false}
        animate={{ scale: isOpen ? 1.1 : 1 }}
        transition={{ duration: 0.3 }}
      >
        <motion.div
          className="absolute inset-x-0 top-0 h-1/2 bg-gradient-to-b from-primary to-secondary"
          initial={false}
          animate={{ y: isOpen ? -30 : 0, rotateX: isOpen ? 60 : 0 }}
          transition={{ duration: 0.5, type: "spring", stiffness: 100 }}
        />
        <motion.div
          className="absolute inset-0 flex flex-col items-center justify-center"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: isOpen ? 1 : 0, scale: isOpen ? 1 : 0.5 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <span className="text-xl font-bold text-primary-light dark:text-secondary-dark">{years}</span>
          <span className="text-xs text-gray-600 dark:text-gray-400">{years === 1 ? 'year' : 'years'}</span>
        </motion.div>
      </motion.div>
    </div>
  );
};

const SkillItem = ({ skill, years }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative" style={{ zIndex: isOpen ? 10 : 1 }}>
      <motion.span
        className="bg-white bg-opacity-20 text-white px-3 py-1 rounded-full text-sm cursor-pointer inline-block"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        {skill}
      </motion.span>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute left-1/2 -translate-x-1/2 mt-2"
            style={{ 
              zIndex: 20,
              pointerEvents: 'none',
            }}
          >
            <ExperienceBox isOpen={isOpen} years={years} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const SkillCategory = ({ title, icon: Icon, skills, color }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`mb-6 rounded-lg shadow-lg ${color} overflow-visible`}>
      <motion.div 
        className="p-4 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Icon className="mr-2 text-white" size={24} />
            <h3 className="text-lg font-semibold text-white">{title}</h3>
          </div>
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <Icon className="text-white" size={24} />
          </motion.div>
        </div>
      </motion.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.3 }}
            className="px-4 pb-4"
          >
            <div className="flex flex-wrap gap-2">
              {skills.map((skill, index) => (
                <SkillItem key={index} skill={skill.name} years={skill.years} />
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const SkillsSection = () => {
  const skillCategories = [
    {
      title: "Web Development",
      icon: Code,
      skills: [
        { name: "React", years: 2 },
        { name: "Flask", years: 1 },
        { name: "Node.js", years: 2 },
        { name: "Express", years: 2 },
        { name: "HTML5", years: 5 },
        { name: "CSS3", years: 5 },
        { name: "JavaScript", years: 5 },
        { name: "TypeScript", years: 1 }
      ],
      color: "bg-blue-600"
    },
    {
      title: "UI/UX Design",
      icon: Palette,
      skills: [
        { name: "Figma", years: 3 },
        { name: "Framer", years: 0.5 },
        { name: "Sketch", years: 0.5 }
      ],
      color: "bg-purple-600"
    },
    {
      title: "Backend & Databases",
      icon: Database,
      skills: [
        { name: "Heroku", years: 2 },
        { name: "Google Cloud Platform", years: 3 },
        { name: "PostgreSQL", years: 2 },
        { name: "MySQL", years: 2 },
        { name: "Firebase", years: 3 }
      ],
      color: "bg-green-600"
    },
    {
      title: "API Development",
      icon: Server,
      skills: [
        { name: "RESTful APIs", years: 2 },
        { name: "API Design", years: 0.5 },
        { name: "Postman", years: 3 }
      ],
      color: "bg-red-600"
    },
    {
      title: "Machine Learning",
      icon: Cpu,
      skills: [
        { name: "TensorFlow", years: 2 },
        { name: "PyTorch", years: 2 },
        { name: "Scikit-learn", years: 2 },
        { name: "Computer Vision", years: 2 },
        { name: "Generative AI", years: 2 }
      ],
      color: "bg-yellow-600"
    },
    {
      title: "Audio Production",
      icon: Music,
      skills: [
        { name: "FL Studio", years: 5 },
        { name: "Sound Design", years: 2 },
        { name: "Mixing", years: 4 },
        { name: "Mastering", years: 4 },
        { name: "Magenta.js", years: 1 }
      ],
      color: "bg-pink-600"
    }
  ];

  return (
    <div className="p-6 bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <h2 className="text-3xl text-primary-light dark:text-secondary-dark font-bold mb-8 text-center">My Skills</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {skillCategories.map((category, index) => (
          <SkillCategory key={index} {...category} />
        ))}
      </div>
    </div>
  );
};

export default SkillsSection;